<template>
    <div @mouseover="isHovered = true" @mouseout="isHovered = false" @click="scrollToSMC" class="smc-cta">
        <CaretIcon :isHovered="isHovered" />
        SuperMinds Casual: <br />
        Behind the Screens
        <CaretIcon :isHovered="isHovered" />
    </div>
</template>

<script>
import VueScrollTo from 'vue-scrollto'

import CaretIcon from '@/components/headerCta/Caret.vue'

const cmcHash = '#smc'

export default {
    name: 'SuperMindsCasualCTA',

    props: {},

    data() {
        return {
            isHovered: false,
        }
    },

    created() {},

    mounted() {
        if (window.location.hash === cmcHash) {
            setTimeout(() => {
                this.scrollToSMC()
            }, 600)
        }
    },

    components: { CaretIcon },

    computed: {},

    methods: {
        scrollToSMC() {
            VueScrollTo.scrollTo(cmcHash, 2000)
        },
    },
}
</script>
