<template>
    <div class="cookie-list">
        <div class="cookie-item">
            <div class="label">Purpose of processing</div>
            <div class="description">providing access to the Website</div>

            <div class="label">Data scope</div>
            <div class="description">IP address</div>

            <div class="label">Legal basis for processing</div>
            <div class="description">
                Article 6, paragraph 1, point (b) of the GDPR - legal authorization to process data necessary for the
                execution of the contract
            </div>

            <div class="label">Data processing period</div>
            <div class="description">
                until the expiration of the statute of limitations for requests related to access to the website
            </div>
        </div>

        <div class="cookie-item">
            <div class="label">Purpose of processing</div>
            <div class="description">site traffic testing</div>

            <div class="label">Data scope</div>
            <div class="description">
                IP address, cookies [cookies of third-party tools: Facebook Pixel, LinkedIn, Google Analytics, Google
                Ads, Twitter]
            </div>

            <div class="label">Legal basis for processing</div>
            <div class="description">
                Article 6, paragraph 1, point (a) of the GDPR - consent of the subject of data processing
            </div>

            <div class="label">Data processing period</div>
            <div class="description">
                until the data is no longer relevant or until the subject of data processing withdraws consent
            </div>
        </div>

        <div class="cookie-item">
            <div class="label">Purpose of processing</div>
            <div class="description">protection against claims, initiation of claims</div>

            <div class="label">Data scope</div>
            <div class="description">
                e-mail address, name, other personal data voluntarily provided by the subject of data processing
            </div>

            <div class="label">Legal basis for processing</div>
            <div class="description">
                Article 6, paragraph 1, point (f) of the GDPR - the legitimate interest of the data controller in the
                protection of claims and the direction of claims
            </div>

            <div class="label">Data processing period</div>
            <div class="description">
                until the expiration of the statute of limitations for claims related to access to the website and the
                user's actions on the website, calculated from the moment of the user's last visit to the website
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CookieTableMobile',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: {},

    computed: {},

    methods: {},
}
</script>
