<template>
    <section class="conference-masterclass">
        <div class="container">
            <p>
                SuperMinds don’t necessarily think alike, but they are eager to share, review and question what they
                know. Join us for a series of knowledge-sharing events, big or small, as we bring together all the
                brightest minds in the tech industry in Zagreb.
            </p>
        </div>

        <div class="conference">
            <div class="container">
                <h2>The conference</h2>

                <div class="image-group flipped">
                    <img src="../assets/superminds-conference.jpg" alt="Supermind conference" />

                    <div class="description">
                        <ul class="conference-description">
                            <li>8 talks by the leading experts from the tech industry</li>
                            <li>2 days of fresh perspectives on mobile development</li>
                            <li>300 seats per day</li>
                            <li>Exclusive company ticket packages (buy 5, get 1 free)</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="masterclasses">
            <div class="container">
                <h2 class="text-right">Masterclasses</h2>

                <div class="image-group">
                    <img src="../assets/superminds-masterclass.jpg" alt="Supermind masterclass" />

                    <div class="description">
                        <ul class="masterclasses-description">
                            <li>Masterclasses by our keynote speakers</li>
                            <li>An opportunity to dig deeper into the conference topics and more</li>
                            <li>Up to 30 attendees per masterclass</li>
                            <li>An excellent occasion to meet and mingle with like minded professionals</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'ConferenceMasterclass',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: {},

    computed: {},

    methods: {},
}
</script>
