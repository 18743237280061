<template>
    <div class="speaker-bio animated fade-in">
        <ClosePopup @click.native="closePopup" class="close-popup" />
        <perfect-scrollbar class="bio-content xl">
            <h1>About<br />Filip Debelić</h1>
            <h2>Android Team Lead</h2>

            <p class="padded">
                Filip has been in Android development for the last 10 years, throughout different domains – from
                streaming to literal garbage. Or waste management, as they tend to call it in high places. He’s
                currently an Android team lead at Superology, working on sports betting and entertainment apps. In his
                free time, he tries to explore the world as much as possible and maintains a farm in Farming Simulator.
            </p>

            <h1>About<br />Luka Leopoldović</h1>
            <h2>Senior Android Developer</h2>

            <p>
                Luka stepped into mobile development in 2011, when he published his first game to PlayStore. Since then,
                he has fully migrated from mobile game development to native Android and is currently a mobile tech lead
                in Superology, where he works on scaling Android architecture & sports statistics integration. He is a
                former member of a punk-rock band and an occasional dungeon master.
            </p>
        </perfect-scrollbar>
    </div>
</template>

<script>
import ClosePopup from '@/components/speakers/ClosePopup.vue'

export default {
    name: 'BioLukaFilip',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: { ClosePopup },

    computed: {},

    methods: {
        closePopup() {
            this.$emit('close')
        },
    },
}
</script>
