<template>
    <div class="container">
        <div class="title-stats padded">
            <h2>
                2021<br class="md-visible" />
                Super<br class="md-visible" />minds
            </h2>

            <div class="conference-stats">
                <div class="stat"><span class="num">4</span> <span class="text">speakers</span></div>
                <div class="stat"><span class="num">150</span> <span class="text">visitors</span></div>
                <div class="stat">
                    <span class="num">98%</span> <span class="text">people said they <br />would attend again</span>
                </div>
            </div>
        </div>

        <div class="gallery-images">
            <div class="image-row">
                <img src="../assets/gallery/superminds-gallery-1.jpg" alt="Superminds gallery image" />
                <img src="../assets/gallery/superminds-gallery-2.jpg" alt="Superminds gallery image" />
            </div>

            <div class="image-row triple">
                <img src="../assets/gallery/superminds-gallery-3.jpg" alt="Superminds gallery image" />
                <img src="../assets/gallery/superminds-gallery-4.jpg" alt="Superminds gallery image" />
                <img src="../assets/gallery/superminds-gallery-5.jpg" alt="Superminds gallery image" />
            </div>

            <div class="image-row">
                <img src="../assets/gallery/superminds-gallery-6.jpg" alt="Superminds gallery image" />
                <img src="../assets/gallery/superminds-gallery-7.jpg" alt="Superminds gallery image" />
            </div>

            <div class="image-row">
                <img src="../assets/gallery/superminds-gallery-8.jpg" alt="Superminds gallery image" />
                <img src="../assets/gallery/superminds-gallery-9.jpg" alt="Superminds gallery image" />
            </div>

            <div class="image-row">
                <img src="../assets/gallery/superminds-gallery-10.jpg" alt="Superminds gallery image" />
                <img src="../assets/gallery/superminds-gallery-11.jpg" alt="Superminds gallery image" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SupermindsGallery',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: {},

    computed: {},

    methods: {},
}
</script>
