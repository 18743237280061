<template>
    <section class="hero-header">
        <div class="dark-overlay">
            <div class="container">
                <div class="hero-inner">
                    <div class="superminds-logo">
                        <img src="../assets/superminds-logo-white.svg" alt="Superminds" />
                    </div>
                    <div class="conference-date">
                        <div class="date">
                            14-15<br />
                            september<br />
                            2022
                        </div>
                        <div class="location">
                            <div class="pin"><img src="../assets/map-pin.svg" alt="Map pin" /> Academy of Music,</div>
                            <div class="city">Zagreb, Croatia</div>
                        </div>
                    </div>
                    <div class="superminds-header-title">
                        <div class="hero-title">
                            <h1>Superminds:<br /><span>Mind the app</span></h1>
                            <h2>Mobile development<br />conference & masterclass</h2>
                        </div>
                    </div>
                    <div class="hero-header-bottom">
                        <img class="powered-by" src="../assets/superology-logo-white.svg" alt="Superology" />
                    </div>
                </div>
            </div>
            <SuperMindsCasualCTA />
        </div>
    </section>
</template>

<script>
import SuperMindsCasualCTA from '@/components/headerCta/SuperMindsCasualCTA.vue'

export default {
    name: 'HeroHeader',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: { SuperMindsCasualCTA },

    computed: {},

    methods: {},
}
</script>
