<template>
    <div class="speaker-box">
        <div class="speaker-box-talk speaker-bg" :class="speaker.bgClass">
            <h3>TALK<br />{{ speaker.talkTitle }}</h3>
            <h4 v-if="!speakerNameDislocated">{{ speaker.name }}</h4>
        </div>
        <div class="talk-description">
            <h4 v-if="speakerNameDislocated">{{ speaker.name }}</h4>
            {{ speaker.talkDescription }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'CasualTalkSpeakerBox',

    props: {
        speaker: {
            required: true,
            type: Object,
        },
    },

    data() {
        return {}
    },

    created() {},

    components: {},

    computed: {
        speakerNameDislocated() {
            return this.screenWidth < 768
        },

        screenWidth() {
            return this.$store.getters.getScreenWidth
        },
    },

    methods: {},
}
</script>
