<template>
    <div class="speaker-bio animated fade-in">
        <ClosePopup @click.native="closePopup" class="close-popup" />
        <div class="bio-content">
            <h1>About<br />Christina Lee</h1>
            <h2>Senior software engineer at Pinterest</h2>

            <p>
                Christina is a senior software engineer at Pinterest, where she works on building frameworks to make
                other engineers more productive. When not at work, she likes spending time with her talking dog, who
                knows 90 words and counting.
            </p>
        </div>
    </div>
</template>

<script>
import ClosePopup from '@/components/speakers/ClosePopup.vue'

export default {
    name: 'BioChristinaLee',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: { ClosePopup },

    computed: {},

    methods: {
        closePopup() {
            this.$emit('close')
        },
    },
}
</script>
