<template>
    <section class="footer">
        <a href="https://superology.com" target="_blank">
            <img src="../../assets/superology-logo-yellow.svg" alt="Superology" />
        </a>

        superminds@superology.com

        <router-link class="cookie-policy-link" to="/cookie-policy">Cookie policy</router-link>
    </section>
</template>

<script>
export default {
    name: 'Footer',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: {},

    computed: {},

    methods: {},
}
</script>
