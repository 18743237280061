<template>
    <section class="mini-header">
        <div class="logo-wrap">
            <router-link to="/"><img src="../../assets/superminds-logo-white.svg" alt="Superminds" /></router-link>
        </div>
    </section>
</template>

<script>
export default {
    name: 'MiniHeader',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: {},

    computed: {},

    methods: {},
}
</script>
