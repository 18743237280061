<template>
    <div class="speaker-bio animated fade-in">
        <ClosePopup @click.native="closePopup" class="close-popup" />
        <div class="bio-content">
            <h1>About<br />Mattt</h1>
            <h2>Creator of NSHipster.com</h2>

            <p>
                Mattt spent the last decade steeped in the Apple developer ecosystem, writing for NSHipster and Flight
                School, building projects like Alamofire and swift-doc, and contributing to Swift Package Manager. He
                believes that abstraction is a form of decadence, that any vision of utopia must be rooted in play, and
                that technology can be used to promote virtue (if we try).
            </p>
        </div>
    </div>
</template>

<script>
import ClosePopup from '@/components/speakers/ClosePopup.vue'

export default {
    name: 'BioMattt',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: { ClosePopup },

    computed: {},

    methods: {
        closePopup() {
            this.$emit('close')
        },
    },
}
</script>
