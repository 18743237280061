<template>
    <div class="cookie-policy">
        <MiniHeader />
        <section class="content-grid">
            <h1>Cookie policy</h1>

            <p>
                When you use our website, we and third-party companies collect information by using cookies and other
                technologies such as pixel tags (for simplicity we refer to all such technologies as 'cookies').
            </p>

            <p>
                Further we will explain what cookies are, how we use them, how third-parties we partner with may use
                cookies, your choices regarding cookies and any other further information you should know about.
            </p>

            <h2>1. What are cookies</h2>

            <p>
                Cookies are small pieces of text sent by your web browser by a website you visit. A cookie file is
                stored in your web browser, it allows the Service or a third-party to recognize you, make your next
                visit easier and the Service more useful to you. A pixel tag (sometimes called a web beacon) is an
                invisible image with a line of code which is placed within an email message or on a web page. NOTE:
                Cookies used by our website are not viruses, malware, or other forms of malicious code and are not used
                to collect user data or to monitor the activities of our website users after leaving our site.
            </p>

            <h2>2. Information collected via cookies and other technology</h2>

            <p>
                The cookies used on our website do not collect directly identifiable personal data such as name,
                address, email address etc. However, certain cookies may collect information which relate to a unique ID
                or another identifier which, when combined with other information, allow a profile to be created.
            </p>

            <h2>3. Types of cookies</h2>

            <p>
                There are different types of cookies which are used to do different things such as letting you navigate
                between different pages on the website efficiently, remembering preferences you have given, helping us
                to identify ways to improve your overall site experience, provide you with advertising, etc.
            </p>

            <p>
                To help you understand the different types of cookies we use on this website and what they do, we have
                grouped cookies into two categories as described below.
            </p>

            <p>
                <strong>"First party</strong> cookies are cookies stored on your device by us using our website. In this
                case, that means cookies that our website “published” and stored on your computer the moment you visited
                this website / accepted or declined cookies on this website.
            </p>

            <p>
                <strong>"Third party"</strong> cookies are cookies designed and “published” by our third parties. Our
                website only uses credible third-party cookies, and we do not allow any third party to use these cookies
                other than for the purposes listed in this policy.
            </p>

            <p>
                Secondly, cookies can be distinguished by how long the cookie remains ("duration") on the user’s device
                after leaving the website/app:
            </p>

            <p>
                <strong>Temporary or "session" cookies</strong> are removed for the user’s device after the browser has
                been closed. Websites and apps use these cookies to store temporary data such as items in a shopping
                cart.
            </p>

            <p>
                <strong>Persistent cookies</strong> remain on your device after you close your internet browser.
                Websites use them to save certain pertinent data such as e.g. username and/or password, the language you
                chose to use during your first visit etc. These cookies may remain on your device for days, months and
                even years or until they are deleted by the user
            </p>

            <p>
                Please note that we cannot influence or control third-party cookies that are already on your computer at
                the time of visiting our website, nor do we have the ability to know what information such cookies
                collect. We recommend that you inform yourself about potential dangers at
                http://www.youronlinechoices.eu To find out more about cookies visit aboutcookies.org,
                allaboutcookies.org or Network Advertising Initiative: http://www.networkadvertising.org/
            </p>

            <h2>4. How Superology uses cookies?</h2>

            <p>We use cookies for the following purposes:</p>

            <ul>
                <li>to enable certain functions of the Service,</li>
                <li>to provide analytics,</li>
                <li>to store your preferences.</li>
            </ul>

            <p>
                Based on their category and/or purpose, we have grouped the cookies used on our sites in two categories
                as set out below:
            </p>

            <h3>Necessary/essential cookies</h3>

            <p>
                These cookies help make our website usable by enabling basic functions. The website cannot function
                properly without them. These cookies don't gather any information about you that could be used for
                marketing or remembering where you've been on the internet.
            </p>

            <p>
                These cookies enable our website to remember information that changes the way our website behaves or
                looks, like the preferences on the cookie consent. They don’t contain personal information such as names
                and email addresses, and are used to improve your user experience of a website. These cookies do not
                identify you as an individual.
            </p>

            <p>
                As these cookies are necessary for the correct functioning of our website, you are unable to control
                them.
            </p>

            <h3>Statistics/Analytical cookies</h3>

            <p>
                Statistic cookies collect data for statistical purposes on how visitors use a website; (e.g. gathering
                data about visits to the website, including numbers of visitors and visits, length of time spent on the
                website, pages clicked on or where visitors have come from).
            </p>

            <h2>5. Third-party cookies</h2>

            <p>
                As well as the cookies we use, we work with third-party companies who place cookies on your device.
                Please note that the data they collect may be subject to their own privacy policies.
            </p>

            <p>
                When working with third-party companies we take steps to protect your data. For example, we place
                contractual limits on how data collected about people using our services is used and we regularly audit
                our sites to make sure only cookies we have authorised are being used.
            </p>

            <p>For a list of third parties we use, please see our Cookies List below.</p>

            <Component :is="cookieTable" />

            <h2>6. How to manage cookies?</h2>

            <p>
                Once you have given us your cookie consent, we will store the cookie on your device to be used for your
                future visits to our website. There are two ways in which you can manage your consent for cookies:
            </p>

            <p>
                1. you can modify your consent for the different types of cookies through the cookie banner/pop-up
                window;
            </p>

            <p>2. you can unsubscribe (opt-out) from already accepted Cookies by clearing your browser cookies.</p>

            <p>
                Alternatively, you can turn off/block cookies through settings in your internet browser by declining to
                download certain or all cookies. These settings are usually found in the “options”, “preferences” or
                “settings” menus in your browser. Note that you can download third party apps that can help you with
                managing cookies.
            </p>

            <p>
                Once you have given us your cookie consent, we will store the cookie on your device to be used for your
                future visits to this website. If, at any time, you wish to withdraw your cookie consent, you will have
                to manually erase our cookies through the settings in your internet browser.
            </p>

            <p>
                Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use
                all of the features we offer, you may not be able to store your preferences, and some of our pages might
                not display properly or you may significantly reduce functionality of individual websites.
            </p>

            <p>
                The following links may be useful if you are interested in more details regarding cookies management for
                certain browsers:
            </p>

            <ul>
                <li>
                    <a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank">Google Chrome</a>
                </li>
                <li>
                    <a
                        href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
                        target="_blank"
                        >Mozilla Firefox</a>
                </li>
                <li>
                    <a href="https://support.microsoft.com/en-us/help/260971/description-of-cookies" target="_blank">Internet Explorer</a>
                </li>
                <li>
                    <a
                        href="https://support.google.com/android/?hl=en&amp;answer=2425067%23topic=7313011"
                        target="_blank"
                        >Android Browser</a>
                </li>
                <li><a href="https://www.opera.com/help/mobile/android" target="_blank">Opera and Opera Mobile</a></li>
                <li>
                    <a
                        href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                        target="_blank"
                        >Safari (Desktop) and Safari (Mobile)</a>
                </li>
            </ul>

            <p>
                If the matters of privacy and data protection on the internet interest you, further information may be
                found at the Electronic Frontier Foundation website and we further recommend you consider downloading
                Privacy Badger, a free internet privacy protection tool.
            </p>

            <p>We reserve the right to occasional changes to this cookie policy.</p>
        </section>
        <Footer />
    </div>
</template>

<script>
import CookieTable from '@/components/cookie-policy/CookieTable.vue'
import CookieTableMobile from '@/components/cookie-policy/CookieTableMobile.vue'
import Footer from '@/components/layout/Footer.vue'
import MiniHeader from '@/components/layout/MiniHeader.vue'

export default {
    name: 'CookiePolicy',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: { Footer, MiniHeader, CookieTable, CookieTableMobile },

    computed: {
        screenWidth() {
            return this.$store.getters.getScreenWidth
        },

        cookieTable() {
            return this.screenWidth >= 1280 ? 'CookieTable' : 'CookieTableMobile'
        },
    },

    methods: {},
}
</script>
