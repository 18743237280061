import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        cookieStorageKey: 'superMindsCookiePreferences',
        cookiePopupVisible: false,
        cookiePreferences: null,
        screenWidth: 0,
    },
    getters: {
        getCookiePopupVisible: state => state.cookiePopupVisible,

        getCookiePreferences: state => state.cookiePreferences,

        getCookieStorageKey: state => state.cookieStorageKey,

        getScreenWidth: state => state.screenWidth,
    },
    mutations: {
        setCookiePreferences(state, payload) {
            // save the preferences object to localStorage
            localStorage.setItem(state.cookieStorageKey, JSON.stringify(payload))

            // save it into vuex
            state.cookiePreferences = payload
        },

        setCookiePopupVisible(state, payload) {
            state.cookiePopupVisible = payload
        },

        setScreenWidth(state, payload) {
            state.screenWidth = payload
        },
    },
    actions: {
        initCookiePreferences({ getters }) {
            const cookiePreferences = getters.getCookiePreferences

            // check if there are any preferences from a previous visit
            if (cookiePreferences?.analytics) {
                const googleCode = `window.dataLayer = window.dataLayer || [];
                                      function gtag(){dataLayer.push(arguments)};
                                      gtag("js", new Date());
                                      gtag("config", "UA-69182172-3");`

                // Create a script element
                const googleScript = document.createElement('script')

                // Set attributes
                googleScript.setAttribute('type', 'text/javascript')

                googleScript.setAttribute('language', 'javascript')

                // Create inline script
                const inlineGoogleScript = document.createTextNode(googleCode)

                // Append to script
                googleScript.appendChild(inlineGoogleScript)

                // Append script
                document.head.insertAdjacentElement('beforeend', googleScript)
            }

            if (cookiePreferences?.marketing) {
                const pixelCode = `!function(f,b,e,v,n,t,s)
                                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                                        n.queue=[];t=b.createElement(e);t.async=!0;
                                        t.src=v;s=b.getElementsByTagName(e)[0];
                                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                                    'https://connect.facebook.net/en_US/fbevents.js');
                                    fbq('init', '1672239356484526');
                                    fbq('track', 'PageView');`

                const linkedinCode = `_linkedin_partner_id = "4655329";
                                    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                                    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
                                    (function(l) {
                                    if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
                                    window.lintrk.q=[]}
                                    var s = document.getElementsByTagName("script")[0];
                                    var b = document.createElement("script");
                                    b.type = "text/javascript";b.async = true;
                                    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                                    s.parentNode.insertBefore(b, s);})(window.lintrk);`

                // Create a script element
                const pixelScript = document.createElement('script')
                const linkedinScript = document.createElement('script')

                // Set attributes
                pixelScript.setAttribute('type', 'text/javascript')

                pixelScript.setAttribute('language', 'javascript')

                linkedinScript.setAttribute('type', 'text/javascript')

                linkedinScript.setAttribute('language', 'javascript')

                // Create inline script
                const inlinePixelScript = document.createTextNode(pixelCode)
                const inlineLinkedinScript = document.createTextNode(linkedinCode)

                // Append to script
                pixelScript.appendChild(inlinePixelScript)

                linkedinScript.appendChild(inlineLinkedinScript)

                // Append script
                document.head.insertAdjacentElement('beforeend', pixelScript)

                document.head.insertAdjacentElement('beforeend', linkedinScript)
            }
        },
    },
})
