<template>
    <section class="our-sponsors">
        <div class="container">
            <h2>Our sponsors</h2>

            <div class="sponsors">
                <div class="sponsor-group">
                    <div class="sponsor">
                        <a href="https://www.joberty.hr" target="_blank" rel="nofollow">
                            <img src="../../assets/joberty.svg" alt="Joberty" />
                        </a>
                    </div>

                    <div class="sponsor">
                        <a href="https://www.boombox.eu/hr/" target="_blank" rel="nofollow">
                            <img src="../../assets/boom-box.svg" alt="Boom box" />
                        </a>
                    </div>

                    <div class="sponsor">
                        <a href="https://thegarden.hr/" target="_blank" rel="nofollow">
                            <img src="../../assets/garden-brewery.svg" alt="The Garden Brewery" />
                        </a>
                    </div>
                </div>

                <div class="sponsor-group">
                    <div class="sponsor">
                        <a href="https://progame.gg" target="_blank" rel="nofollow">
                            <img src="../../assets/progame.svg" alt="Pro Game" />
                        </a>
                    </div>

                    <div class="sponsor">
                        <a href="https://www.laqo.hr/" target="_blank" rel="nofollow">
                            <img class="laqo" src="../../assets/laqo.svg" alt="Laqo" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'OurSponsors',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: {},

    computed: {},

    methods: {},
}
</script>
