<template>
    <div class="speaker-bio animated fade-in">
        <ClosePopup @click.native="closePopup" class="close-popup" />
        <div class="bio-content">
            <h1>About<br />Eliza Camber</h1>
            <h2>Android developer at Pixplicity</h2>

            <p>
                Eliza has been involved in the tech communities since 2013 as a volunteer, organiser, lead, or a
                speaker. In her spare time she loves travelling, reading, doing puzzles, board games and long walks with
                her dog.
            </p>

            <p>Twitter / Github: @ElizaCamber</p>
        </div>
    </div>
</template>

<script>
import ClosePopup from '@/components/speakers/ClosePopup.vue'

export default {
    name: 'BioElizaCamber',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: { ClosePopup },

    computed: {},

    methods: {
        closePopup() {
            this.$emit('close')
        },
    },
}
</script>
