import Vue from 'vue'
import PerfectScrollbar from 'vue2-perfect-scrollbar'

import App from './App.vue'
import router from './router'
import store from './store'

import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

Vue.use(PerfectScrollbar)

require('./scss/main.scss')

Vue.config.productionTip = false

new Vue({
    router,
    store,
    created() {
        window.addEventListener('resize', this.handleResize)

        this.handleResize()

        this.handleCookies()
    },

    computed: {
        cookieStorageKey() {
            return this.$store.getters.getCookieStorageKey
        },
    },

    methods: {
        handleCookies() {
            // check localstorage
            const cookiePreferences = localStorage.getItem(this.cookieStorageKey)

            if (cookiePreferences) {
                this.$store.commit('setCookiePreferences', JSON.parse(cookiePreferences))

                this.$store.dispatch('initCookiePreferences')
            } else {
                this.$store.commit('setCookiePopupVisible', true)
            }
        },

        handleResize() {
            this.$store.commit('setScreenWidth', window.innerWidth)
        },
    },

    render: h => h(App),
}).$mount('#app')
