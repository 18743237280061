<template>
    <div class="speaker-bio animated fade-in">
        <ClosePopup @click.native="closePopup" class="close-popup" />
        <div class="bio-content">
            <h1>About<br />Garrick Toubassi</h1>
            <h2>Investor, Advisor, ex-Google/Stripe/NeXT</h2>

            <p>
                Garrick is a 25 year veteran of Silicon Valley, starting his career programming Objective-C at NeXT as
                part of the AppKit team. He worked at Google as the VP of Engineering responsible for popular consumer
                products such as Gmail, Calendar, and Google Meet. Outside of technical pursuits, Garrick is a fan of
                bbq, and his wife reminds him he is slowly killing himself with the consumption of unhealthy amounts of
                red meat.
            </p>
        </div>
    </div>
</template>

<script>
import ClosePopup from '@/components/speakers/ClosePopup.vue'

export default {
    name: 'BioGarrickToubassi',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: { ClosePopup },

    computed: {},

    methods: {
        closePopup() {
            this.$emit('close')
        },
    },
}
</script>
