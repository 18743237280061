<template>
    <div class="container">
        <div class="title-stats dark">
            <h2>
                2022<br class="md-visible" />
                Super<br class="md-visible" />minds
            </h2>

            <div class="conference-stats">
                <div class="stat"><span class="num">2</span> <span class="text">days</span></div>
                <div class="stat"><span class="num">9</span> <span class="text">speakers</span></div>
                <div class="stat"><span class="num">400+</span> <span class="text">attendees</span></div>
            </div>
        </div>

        <div class="gallery-images">
            <div class="image-row">
                <img src="../assets/gallery/sm-2022-1.jpg" alt="Superminds gallery image" />
                <img src="../assets/gallery/sm-2022-2.jpg" alt="Superminds gallery image" />
            </div>

            <div class="image-row">
                <img src="../assets/gallery/sm-2022-3.jpg" alt="Superminds gallery image" />
                <img src="../assets/gallery/sm-2022-4.jpg" alt="Superminds gallery image" />
            </div>

            <div class="image-row">
                <img src="../assets/gallery/sm-2022-5.jpg" alt="Superminds gallery image" />
                <img src="../assets/gallery/sm-2022-6.jpg" alt="Superminds gallery image" />
            </div>

            <div class="image-row">
                <img src="../assets/gallery/sm-2022-7.jpg" alt="Superminds gallery image" />
                <img src="../assets/gallery/sm-2022-8.jpg" alt="Superminds gallery image" />
            </div>

            <div class="image-row">
                <img src="../assets/gallery/sm-2022-9.jpg" alt="Superminds gallery image" />
                <img src="../assets/gallery/sm-2022-10.jpg" alt="Superminds gallery image" />
            </div>

            <div class="image-row">
                <img src="../assets/gallery/sm-2022-11.jpg" alt="Superminds gallery image" />
                <img src="../assets/gallery/sm-2022-12.jpg" alt="Superminds gallery image" />
            </div>

            <div class="image-row">
                <img src="../assets/gallery/sm-2022-13.jpg" alt="Superminds gallery image" />
                <img src="../assets/gallery/sm-2022-14.jpg" alt="Superminds gallery image" />
            </div>

            <div class="image-row">
                <img src="../assets/gallery/sm-2022-15.jpg" alt="Superminds gallery image" />
                <img src="../assets/gallery/sm-2022-16.jpg" alt="Superminds gallery image" />
            </div>

            <div class="image-row">
                <img src="../assets/gallery/sm-2022-17.jpg" alt="Superminds gallery image" />
                <img src="../assets/gallery/sm-2022-18.jpg" alt="Superminds gallery image" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SupermindsGallery2022',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: {},

    computed: {},

    methods: {},
}
</script>
