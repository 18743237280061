<template>
    <div class="omnom-nom visible">
        <div class="heading">Hiya! Have some cookies.</div>

        <div class="description">
            This site needs cookies to function. We all do. Along with the necessary ones, we would like to use
            additional cookies to improve your experience.
        </div>

        <div class="biscuit-options">
            <div class="biscuit-item">
                <div class="biscuit-type">Necessary cookies</div>
                <router-link to="/cookie-policy">
                    <img class="biscuit-info-ico" src="../assets/info.svg" alt="Info" />
                </router-link>
            </div>
            <div class="biscuit-item">
                <div class="biscuit-type">Analytics cookies</div>
                <div class="biscuit-prefs">
                    <input v-model="cookies.analytics" class="toggle" type="checkbox" />
                    <router-link to="/cookie-policy">
                        <img class="biscuit-info-ico" src="../assets/info.svg" alt="Info" />
                    </router-link>
                </div>
            </div>

            <div class="biscuit-item">
                <div class="biscuit-type">Marketing cookies</div>
                <div class="biscuit-prefs">
                    <input v-model="cookies.marketing" class="toggle" type="checkbox" />
                    <router-link to="/cookie-policy">
                        <img class="biscuit-info-ico" src="../assets/info.svg" alt="Info" />
                    </router-link>
                </div>
            </div>
        </div>

        <div class="biscuit-disclaimer">
            We’ll assume you’re ok with this, but you can opt out any time. Read more about it in our
            <router-link to="/cookie-policy">Cookie Policy</router-link>.
        </div>

        <div class="biscuit-footer">
            <div @click="saveCookiePreferences" class="accept-selection">accept selection</div>
            <div @click="acceptAll" class="accept-all">accept all</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ConsentPopup',

    props: {},

    data() {
        return {
            cookies: {
                analytics: false,
                marketing: false,
            },
        }
    },

    created() {},

    components: {},

    computed: {},

    methods: {
        saveCookiePreferences() {
            this.$store.commit('setCookiePreferences', { ...this.cookies })

            this.$store.commit('setCookiePopupVisible', false)

            // TODO: Do GA stuff
            this.$store.dispatch('initCookiePreferences')
        },

        acceptAll() {
            Object.keys(this.cookies).forEach(key => (this.cookies[key] = true))

            this.saveCookiePreferences()
        },
    },
}
</script>
