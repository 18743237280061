<template>
    <div id="app">
        <router-view />
        <ConsentPopup v-if="cookiePopupVisible" />
    </div>
</template>

<script>
import ConsentPopup from '@/components/ConsentPopup.vue'

export default {
    components: { ConsentPopup },

    computed: {
        cookiePopupVisible() {
            return this.$store.getters.getCookiePopupVisible
        },
    },
}
</script>
