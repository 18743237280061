<template>
    <svg
        @mouseover="isHovered = true"
        @mouseout="isHovered = false"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M28.2353 30L15 15.3191L1.69683 30H0L14.1176 14.4487L0.81448 0H2.51131L14.9321
                13.6364L27.3529 0H28.9819L15.7466 14.4487L30 30H28.2353Z"
            :fill="fill"
        />
    </svg>
</template>

<script>
export default {
    name: 'ClosePopup',

    props: {},

    data() {
        return {
            isHovered: false,
        }
    },

    created() {},

    components: {},

    computed: {
        fill() {
            return this.isHovered ? '#3CC986' : 'white'
        },
    },

    methods: {},
}
</script>
