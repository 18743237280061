<template>
    <table>
        <thead>
            <tr>
                <th>Purpose of processing</th>
                <th>Data scope</th>
                <th>Legal basis for processing</th>
                <th>Data processing period</th>
            </tr>
        </thead>

        <tbody>
            <tr>
                <td>providing access to the Website</td>
                <td>IP address</td>
                <td>
                    Article 6, paragraph 1, point (b) of the GDPR - legal authorization to process data necessary for
                    the execution of the contract
                </td>
                <td>
                    until the expiration of the statute of limitations for requests related to access to the website
                </td>
            </tr>

            <tr>
                <td>site traffic testing</td>
                <td>
                    IP address, cookies [cookies of third-party tools: Facebook Pixel, LinkedIn, Google Analytics,
                    Google Ads, Twitter]
                </td>
                <td>Article 6, paragraph 1, point (a) of the GDPR - consent of the subject of data processing</td>
                <td>until the data is no longer relevant or until the subject of data processing withdraws consent</td>
            </tr>

            <tr>
                <td>protection against claims, initiation of claims</td>
                <td>
                    e-mail address, name, other personal data voluntarily provided by the subject of data processing
                </td>
                <td>
                    Article 6, paragraph 1, point (f) of the GDPR - the legitimate interest of the data controller in
                    the protection of claims and the direction of claims
                </td>
                <td>
                    until the expiration of the statute of limitations for claims related to access to the website and
                    the user's actions on the website, calculated from the moment of the user's last visit to the
                    website
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: 'CookieTable',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: {},

    computed: {},

    methods: {},
}
</script>
