<template>
    <div class="home">
        <HeroHeader />
        <ConferenceMasterclass />
        <SupermindSpeakers />

        <BehindTheScreens />

        <div class="superminds-gallery">
            <SupermindsGallery2022 />
            <SupermindsGallery2021 v-if="allGalleriesVisible" />

            <div class="container">
                <button @click="allGalleriesVisible = !allGalleriesVisible" class="gallery-toggle">
                    {{ galleryToggleText }}
                </button>
            </div>
        </div>

        <OurSponsors />
        <Footer />
    </div>
</template>

<script>
import BehindTheScreens from '@/components/behindTheScreens/BehindTheScreens.vue'
import ConferenceMasterclass from '@/components/ConferenceMasterclass.vue'
import SupermindsGallery2021 from '@/components/Gallery2021.vue'
import SupermindsGallery2022 from '@/components/Gallery2022.vue'
import HeroHeader from '@/components/HeroHeader.vue'
import Footer from '@/components/layout/Footer.vue'
import OurSponsors from '@/components/layout/Sponsors.vue'
import SupermindSpeakers from '@/components/speakers/Speakers.vue'

export default {
    name: 'HomeView',

    data() {
        return {
            allGalleriesVisible: false,
        }
    },

    components: {
        BehindTheScreens,
        OurSponsors,
        SupermindSpeakers,
        Footer,
        SupermindsGallery2021,
        SupermindsGallery2022,
        ConferenceMasterclass,
        HeroHeader,
    },

    computed: {
        galleryToggleText() {
            return this.allGalleriesVisible ? 'See less' : 'See 2021 SuperMinds photos'
        },
    },
}
</script>
