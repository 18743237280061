import Vue from 'vue'
import VueRouter from 'vue-router'

import CookiePolicy from '@/views/CookiePolicy.vue'

import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/cookie-policy',
        name: 'Cookie policy',
        component: CookiePolicy,
    },
]

const router = new VueRouter({
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || { x: 0, y: 0 }
    },
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router
