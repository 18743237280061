<template>
    <div class="speaker-bio animated fade-in">
        <ClosePopup @click.native="closePopup" class="close-popup" />
        <div class="bio-content">
            <h1>About<br />Adis Mustedanagic</h1>
            <h2>Engineering manager at Superology</h2>

            <p>
                Adis is a developer in denial with a decade of experience in mobile, more than half of that in people
                management too. In his free time, it’s a tossup between board/PC games or outdoor activities. Say hi if
                you meet him on the trails!
            </p>
        </div>
    </div>
</template>

<script>
import ClosePopup from '@/components/speakers/ClosePopup.vue'

export default {
    name: 'BioAdisMustedanagic',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: { ClosePopup },

    computed: {},

    methods: {
        closePopup() {
            this.$emit('close')
        },
    },
}
</script>
