<template>
    <svg width="25" height="15" viewBox="0 0 25 15" xmlns="http://www.w3.org/2000/svg">
        <path
            class="caret-path"
            d="M2.9375 0L12.5 9.27125L22.0625 0L25 2.85425L12.5 15L0 2.85425L2.9375 0Z"
            :fill="color"
        />
    </svg>
</template>

<script>
export default {
    name: 'CaretIcon',

    props: {
        isHovered: {
            type: Boolean,
        },
    },

    data() {
        return {}
    },

    created() {},

    components: {},

    computed: {
        color() {
            return this.isHovered ? '#3CC986' : '#020711'
        },
    },

    methods: {},
}
</script>

<style scoped>
.caret-path {
    transition: all 0.3s;
}
</style>
