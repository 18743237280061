<template>
    <div class="speaker-bio animated fade-in">
        <ClosePopup @click.native="closePopup" class="close-popup" />
        <div class="bio-content">
            <h1>About<br />Paul Hudson</h1>
            <h2>Coder, speaker and author of Hacking with Swift</h2>

            <p>
                Paul is a coder, a speaker and an author of Hacking with Swift. He has developed over 100 apps for iOS
                and Android. His tools have been used by Apple, Verizon, Autodesk, GE, and others.
            </p>

            <p>Twitter: @twostraws</p>
        </div>
    </div>
</template>

<script>
import ClosePopup from '@/components/speakers/ClosePopup.vue'

export default {
    name: 'BioPaulHudson',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: { ClosePopup },

    computed: {},

    methods: {
        closePopup() {
            this.$emit('close')
        },
    },
}
</script>
