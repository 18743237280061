<template>
    <div class="speaker-bio animated fade-in">
        <ClosePopup @click.native="closePopup" class="close-popup" />
        <div class="bio-content">
            <h1>About<br />Ivana Lubar</h1>
            <h2>iOS Engineer at Runtastic</h2>

            <p>
                Ivana develops Adidas-owned sports app with 381 million downloads. As much as she likes coding, she is a
                lot into yoga and fitness. She believes that physical activity is an important part of our everyday life
                and loves how technology can support it and make it more fun.
            </p>
        </div>
    </div>
</template>

<script>
import ClosePopup from '@/components/speakers/ClosePopup.vue'

export default {
    name: 'BioIvanaLubar',

    props: {},

    data() {
        return {}
    },

    created() {},

    components: { ClosePopup },

    computed: {},

    methods: {
        closePopup() {
            this.$emit('close')
        },
    },
}
</script>
