<template>
    <section class="supermind-speakers">
        <div class="container">
            <h2>2022 <br class="sm-visible" />SuperMinds <br />Speakers</h2>

            <div class="speakers">
                <div class="speaker-row">
                    <div class="speaker">
                        <div class="speaker-image">
                            <img src="../../assets/speakers/paul-hudson.jpg" alt="Paul Hudson" />

                            <div class="about-speaker desktop">
                                <span @click="setActiveBio('PaulHudson')">About Paul</span>
                            </div>
                        </div>

                        <div class="bio">
                            <h3>Paul Hudson: Custom Layouts in iOS 16</h3>
                            <h4>Coder, speaker and author of Hacking with Swift</h4>
                            <p>
                                We’ve had HStack, VStack, and ZStack since SwiftUI launched, but now with iOS 16 we get
                                full control over layouts and can do literally whatever we want. In this talk Paul will
                                demonstrate the new Layout protocol with lots of example code, and show you how it fits
                                alongside the other layout options thanks to the new AnyLayout view.
                            </p>
                        </div>

                        <div class="about-speaker mobile">
                            <span @click="setActiveBio('PaulHudson')">About Paul</span>
                        </div>
                    </div>

                    <div class="speaker">
                        <div class="speaker-image">
                            <img src="../../assets/speakers/mattt.jpg" alt="Mattt" />
                            <div class="about-speaker desktop">
                                <span @click="setActiveBio('Mattt')">About Mattt</span>
                            </div>
                        </div>

                        <div class="bio">
                            <h3>Mattt: Reality Check</h3>
                            <h4>Creator of NSHipster.com</h4>
                            <p>
                                Mattt will dive into the early 2010s App Store gold rush and the opportunities for the
                                coming decade. You’ll learn about the current state of AR/VR and other emerging tech –
                                and how they’re likely to shape society. About what technologies like radio and TV can
                                tell us about what to expect for the 21st century. Find out how to seize the chances
                                ahead and (hopefully) the wisdom to use your development skills for good.
                            </p>
                        </div>

                        <div class="about-speaker mobile">
                            <span @click="setActiveBio('Mattt')">About Mattt</span>
                        </div>
                    </div>

                    <div class="speaker">
                        <div class="speaker-image">
                            <img src="../../assets/speakers/ivana-lubar.jpg" alt="Ivana Lubar" />
                            <div class="about-speaker desktop">
                                <span @click="setActiveBio('IvanaLubar')">About Ivana</span>
                            </div>
                        </div>

                        <div class="bio">
                            <h3>Ivana Lubar: A jog through Runtastic</h3>
                            <h4>iOS Engineer at Runtastic</h4>
                            <p>
                                Building the apps for an industry that is in a competitive position requires a lot of
                                content and attractive features. If you want to be in a loop but keep your work simple
                                at the same time, good organisation is the most important part. Learn how we organise
                                our apps at Runtastic and find out how we grew from a student project to the adidas
                                owned app.
                            </p>
                        </div>

                        <div class="about-speaker mobile">
                            <span @click="setActiveBio('IvanaLubar')">About Ivana</span>
                        </div>
                    </div>

                    <div class="speaker">
                        <div class="speaker-image">
                            <img src="../../assets/speakers/adis-mustedanagic.jpg" alt="Adis Mustedanagić" />

                            <div class="about-speaker desktop">
                                <span @click="setActiveBio('AdisMustedanagic')">About Adis</span>
                            </div>
                        </div>

                        <div class="bio">
                            <h3>Adis Mustedanagić: You’re not the boss of me</h3>
                            <h4>Engineering manager at Superology</h4>
                            <p>
                                Ever wondered how large scale user-oriented products get built from the inside? Even
                                with no single person pulling all the strings, just the developers and their teams? Join
                                me for a brief walk through a mobile app built on experimenting, failures through the
                                lens of one of the feature teams. Fun thing? Nobody gets to tell them what to do.
                            </p>
                        </div>

                        <div class="about-speaker mobile">
                            <span @click="setActiveBio('AdisMustedanagic')">About Adis</span>
                        </div>
                    </div>

                    <div class="speaker">
                        <div class="speaker-image">
                            <img src="../../assets/speakers/eliza-camber.jpg" alt="Eliza Camber" />
                            <div class="about-speaker desktop">
                                <span @click="setActiveBio('ElizaCamber')">About Eliza</span>
                            </div>
                        </div>

                        <div class="bio">
                            <h3>Eliza Camber: Machine Learning on Android</h3>
                            <h4>Android developer at Pixplicity</h4>
                            <p>
                                Machine learning is one of the most fascinating technologies with endless use cases. As
                                mobile developers, ML is not something most of us can easily learn and implement.
                                Thankfully, for some popular use cases, like text or face recognition, we have tools to
                                help us. From pre-trained models to creating custom ones, we will explore all the
                                available options and tools to implement ML on Android.
                            </p>
                        </div>

                        <div class="about-speaker mobile">
                            <span @click="setActiveBio('ElizaCamber')">About Eliza</span>
                        </div>
                    </div>

                    <div class="speaker">
                        <div class="speaker-image">
                            <img src="../../assets/speakers/christina-lee.jpg" alt="Christina Lee" />

                            <div class="about-speaker desktop">
                                <span @click="setActiveBio('ChristinaLee')">About Christina</span>
                            </div>
                        </div>

                        <div class="bio">
                            <h3>
                                Christina Lee: The Dog and the Tech Lead: What Animal Training Taught Me About Being a
                                Better Mentor
                            </h3>
                            <h4>Senior software engineer at Pinterest</h4>
                            <p>
                                People who start out loving to code and get good at it eventually end up being
                                responsible for teaching others how to get better at coding too. While tech leads
                                typically don’t directly manage others, they are critical in the development of juniors.
                                Being a good tech lead requires more than being good at coding: it implies the ability
                                to teach and motivate, skills that engineers are rarely provided formal training to
                                develop. Christina will explore how becoming a dog trainer inspired her to rethink how
                                she interacts with co-workers, providing actionable tips to make teaching and learning
                                more enjoyable.
                            </p>
                        </div>

                        <div class="about-speaker mobile">
                            <span @click="setActiveBio('ChristinaLee')">About Christina</span>
                        </div>
                    </div>

                    <div class="speaker">
                        <div class="speaker-image">
                            <img src="../../assets/speakers/garrick-toubassi.jpg" alt="Garrick Toubassi" />

                            <div class="about-speaker desktop">
                                <span @click="setActiveBio('GarrickToubassi')">About Garrick</span>
                            </div>
                        </div>

                        <div class="bio">
                            <h3>Garrick Toubassi: Shipping mobile apps in start-ups and big tech</h3>
                            <h4>Investor, Advisor, ex-Google/Stripe/NeXT</h4>
                            <p>
                                In this talk, Garrick will review learning from shipping both successful and
                                unsuccessful mobile apps as a part of startups as well as “big tech”, including
                                consideration of topics such as cross-platform delivery, team organization, and release
                                velocity.
                            </p>
                        </div>

                        <div class="about-speaker mobile">
                            <span @click="setActiveBio('GarrickToubassi')">About Garrick</span>
                        </div>
                    </div>

                    <div class="speaker">
                        <div class="speaker-image">
                            <img
                                src="../../assets/speakers/luka-leopoldovic-filip-debelic.jpg"
                                alt="Luka Leopoldović & Filip Debelić"
                            />

                            <div class="about-speaker desktop">
                                <span @click="setActiveBio('LukaFilip')">About Luka & Filip</span>
                            </div>
                        </div>

                        <div class="bio">
                            <h3>Luka Leopoldović & Filip Debelić: Past, Present and Future (of a product app)</h3>
                            <h4>Android developers at Superology</h4>
                            <p>
                                Five years is a long time in the life of an app, especially while being the foundation
                                of a constantly evolving and growing product. Filip Debelić and Luka Leopoldović will
                                take you on a product app journey and show you how the team behind it scales the
                                processes, codebase, and workflow to adjust, overcome and future-proof for the constant
                                changes and challenges in the fast-growing mobile environment.
                            </p>
                        </div>

                        <div class="about-speaker mobile">
                            <span @click="setActiveBio('LukaFilip')">About Luka & Filip</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <component v-if="bioPopupVisible" :is="activeBioComponent" @close="closePopup" />
    </section>
</template>

<script>
import BioAdisMustedanagic from '@/components/speakers/BioAdisMustedanagic.vue'
import BioChristinaLee from '@/components/speakers/BioChristinaLee.vue'
import BioElizaCamber from '@/components/speakers/BioElizaCamber.vue'
import BioGarrickToubassi from '@/components/speakers/BioGarrickToubassi.vue'
import BioIvanaLubar from '@/components/speakers/BioIvanaLubar.vue'
import BioLukaFilip from '@/components/speakers/BioLukaFilip.vue'
import BioMattt from '@/components/speakers/BioMattt.vue'
import BioPaulHudson from '@/components/speakers/BioPaulHudson.vue'

export default {
    name: 'SupermindSpeakers',

    props: {},

    data() {
        return {
            activeBio: '',
            bioPopupVisible: false,
        }
    },

    created() {
        document.addEventListener('keyup', event => {
            if (event.key === 'Escape') {
                this.closePopup()
            }
        })
    },

    components: {
        BioPaulHudson,
        BioMattt,
        BioIvanaLubar,
        BioAdisMustedanagic,
        BioElizaCamber,
        BioChristinaLee,
        BioGarrickToubassi,
        BioLukaFilip,
    },

    computed: {
        activeBioComponent() {
            return `Bio${this.activeBio}`
        },
    },

    methods: {
        setActiveBio(speaker) {
            this.activeBio = speaker

            this.bioPopupVisible = true

            document.getElementsByTagName('body')[0].classList.add('popup-opened')
        },

        closePopup() {
            this.bioPopupVisible = false

            document.getElementsByTagName('body')[0].classList.remove('popup-opened')
        },
    },
}
</script>
