<template>
    <div id="smc" class="behind-the-screens">
        <div class="container">
            <div class="casual-talk-header">
                <img
                    src="../../assets/superminds-casual-logo.svg"
                    class="superminds-casual-logo"
                    alt="Super Minds Casual"
                />

                <h2>Behind <br />the screens</h2>
            </div>

            <div class="casual-talk-subheader">
                <div class="casual-talk-description">
                    <p>November 24. Gathering at 17h, talks start at 18h.</p>

                    <p>
                        SuperMinds Casual is a series of informal, occasional meetups in Superology headquarters in
                        Zagreb. It’s a colorful combination of tech and dev lifestyle topics, and we like to top it with
                        and beer. Join the convo!
                    </p>

                    <p class="talk-language-info">
                        <img src="../../assets/cro-flag.png" alt="Cro flag" class="cro-flag" /> all talks held in
                        Croatian
                    </p>
                </div>

                <a href="https://s-o.dev/smc11" class="casual-talk-register" target="_blank">Register now</a>
            </div>

            <div class="casual-talk-speakers">
                <CasualTalkSpeakerBox v-for="speaker in speakers" :key="speaker.name" :speaker="speaker" />
            </div>

            <div class="casual-talk-timetable">
                <div class="casual-clock">
                    <img src="../../assets/behindTheScreens/clock.svg" alt="Clock" />
                </div>
                <div class="casual-talk-timetable-row align-right">
                    <div class="casual-talk-timetable-event">
                        <div class="casual-talk-time">17:00-18:00</div>
                        <div class="casual-talk-title">Gathering & mingling at Superology HQ</div>
                    </div>

                    <div class="casual-talk-timetable-event">
                        <div class="casual-talk-time">18:00-18:25 <span>1st talk</span></div>
                        <div class="casual-talk-title">Rust: Buđav Webac + Q&A</div>
                        <div class="casual-talk-speaker">Momir Zmijanac, Frontend Developer at Superology</div>
                    </div>

                    <div class="casual-talk-timetable-event">
                        <div class="casual-talk-time">18:45-19:05 <span>2ND TALK</span></div>
                        <div class="casual-talk-title">Cache me if you can + Q&A</div>
                        <div class="casual-talk-speaker">Sven Vidak, Backend Developer at Superology</div>
                    </div>
                </div>

                <div class="casual-talk-timetable-row">
                    <div class="casual-talk-timetable-event">
                        <div class="casual-talk-time">19:30-19:55 <span>3RD TALK</span></div>
                        <div class="casual-talk-title">Figma - User friendly + Q&A</div>
                        <div class="casual-talk-speaker">
                            Lamia Nuhbegović, Product Design Lead at Robin Friends of Figma Zagreb Community Advocate
                        </div>
                    </div>

                    <div class="casual-talk-timetable-event">
                        <div class="casual-talk-time">20:15-20:40 <span>4TH TALK</span></div>
                        <div class="casual-talk-title">How to train your manager + Q&A</div>
                        <div class="casual-talk-speaker">Hrvoje Crnjak, Engineering Manager at Superology</div>
                    </div>

                    <div class="casual-talk-timetable-event">
                        <div class="casual-talk-time">20:40-XX:XX</div>
                        <div class="casual-talk-title">Pizza & beer</div>
                    </div>
                </div>
            </div>

            <div class="casual-talk-footer">
                <span>Catch a seat, <br />grab a beer!</span>
                <img src="../../assets/beer-bottle.svg" alt="Beer bottle" />
            </div>
        </div>
    </div>
</template>

<script>
import CasualTalkSpeakerBox from '@/components/behindTheScreens/CasualTalkSpeakerBox.vue'

export default {
    name: 'BehindTheScreens',

    props: {},

    data() {
        return {
            speakers: [
                {
                    name: 'Momir Zmijanac, Frontend Developer at Superology',
                    talkTitle: 'Rust: Buđav Webac',
                    talkDescription:
                        'Rust is a fast, reliable, and memory-efficient programming language. However, its use in the web community is still niche, its adoption hasn’t reached a critical mass. Then why is it now utilised to replace JavaScript ecosystem components like minification, transpilation, formatting, compiling, bundling, linting, and more? Should we take Rust (& WASM) seriously as a potential JavaScript killer and the next user interface king? Let’s share a beer and find out together!',

                    bgClass: 'momo-bg',
                },
                {
                    name: 'Sven Vidak, Backend Developer at Superology',
                    talkTitle: 'Cache me if you can',
                    talkDescription:
                        'Delivering content to users as fast as possible has become a crucial component of system design. Many CDN providers give you different control over the distribution and caching of the content of your application. But what happens when default behaviour is no longer enough? In this talk, we will explore the different benefits of customising caching to fit your needs and see how we at Superology did this to fit our needs.',

                    bgClass: 'sven-bg',
                },
                {
                    name: 'Lamia Nuhbegović, Product Design Lead at Robin, Friends of Figma Zagreb Community Advocate',
                    talkTitle: 'Figma - User friendly',
                    talkDescription:
                        'Figma is a user-friendly tool that connects everyone in the design process so teams can deliver better products faster. Explore how you can make an impact with a platform that connects the dots across design, product, and development. Go from developer handoffs to ongoing conversations with a shared language between design and development.',

                    bgClass: 'lamia-bg',
                },
                {
                    name: 'Hrvoje Crnjak, Engineering Manager at Superology',
                    talkTitle: 'How to train your manager',
                    talkDescription:
                        'There’s a famous quote by Stephen Hawking that goes something like this: “If at first you don’t succeed, try management.” And these days, it seems that everyone has a manager. Even managers have their managers. We must learn how to live with them effectively. In this short and (hopefully) sweet talk, we’ll see what good managers do – and bad ones don’t. And try to figure out how to deal with whatever they put in front of us.',

                    bgClass: 'hrvoje-bg',
                },
            ],
        }
    },

    created() {},

    components: { CasualTalkSpeakerBox },

    computed: {},

    methods: {},
}
</script>
